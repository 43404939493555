import {
  IGlobalSchema,
  SchemaBatch,
  EEventTypes,
  ITrackCartEventPayload,
  ITrackCheckoutStepPayload,
  ITrackPageViewPayload,
} from '../interfaces'
import { pushData, sendBeacon } from './pushData'

class YaloAnalytics {
  private constructor() {}
  private static instance: YaloAnalytics

  // all the events that are going to be sent to the server
  private callsQueue: SchemaBatch = []

  private isQueueRunning: boolean = false

  private queueId: ReturnType<typeof setTimeout> | null = null

  // delay time after events are called
  public timeDelay: number = 10000

  // size of the batch that is going to be sent
  public batchSize: number = 3

  public static trackEvents = false

  public static getInstance(): YaloAnalytics {
    if (!YaloAnalytics.instance) {
      YaloAnalytics.instance = new YaloAnalytics()
    }
    window.addEventListener('beforeunload', () => {
      this.instance.cancelTimeouts()
    })
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.instance.cancelTimeouts()
      }
    })

    return YaloAnalytics.instance
  }
  // track a cart event
  public static trackCartEvent(data: ITrackCartEventPayload): void {
    const {
      cartData: payload,
      eventName,
      phoneNumber,
      sessionId,
      viewName,
      storefront,
    } = data
    const eventData: IGlobalSchema = {
      eventType: EEventTypes.INTERACTION,
      eventName,
      viewName,
      sessionId,
      phoneNumber,
      payload,
      storefront,
      errors: [],
    }
    const instance = YaloAnalytics.getInstance()
    instance.enQueue(eventData)
  }
  // track a page view event
  public static trackPageView(data: ITrackPageViewPayload): void {
    const {
      pageData: payload,
      eventName,
      phoneNumber,
      sessionId,
      viewName,
    } = data
    const eventData: IGlobalSchema = {
      eventType: EEventTypes.INTERACTION,
      eventName,
      viewName,
      sessionId,
      phoneNumber,
      payload,
      errors: [],
    }
    const instance = YaloAnalytics.getInstance()
    instance.enQueue(eventData)
  }
  // track checkout steps
  public static trackCheckoutStep(data: ITrackCheckoutStepPayload): void {
    const {
      stepData: payload,
      eventName,
      phoneNumber,
      sessionId,
      viewName,
    } = data
    const instance = YaloAnalytics.getInstance()
    const eventData: IGlobalSchema = {
      eventType: EEventTypes.INTERACTION,
      eventName,
      viewName,
      sessionId,
      phoneNumber,
      payload,
      errors: [],
    }
    instance.enQueue(eventData)
  }

  // add a event to the queue
  private enQueue(data: IGlobalSchema): void {
    if (!YaloAnalytics.trackEvents) return
    this.callsQueue.push(data)
    // prevent duplicated calls
    if (!this.isQueueRunning) {
      // starts the execution of the queue
      this.startQueue()
    }
  }

  private startQueue(): void {
    this.isQueueRunning = true
    // saves the id of the timeout to be able to cancel it
    this.queueId = setTimeout(() => {
      this.cleanQueue()
    }, this.timeDelay)
  }
  // clean queue by sending events
  private async cleanQueue(withFetch: boolean = true): Promise<void> {
    while (this.callsQueue.length > 0) {
      const events = this.callsQueue.splice(0, this.batchSize)
      if (events) {
        try {
          // eslint-disable-next-line no-await-in-loop
          if (withFetch) await pushData(events)
          else sendBeacon(events)
        } catch (error) {
          console.error('Error sending data to analytics', error)
          this.callsQueue.unshift(...events)
        }
      }
    }
    this.isQueueRunning = false
  }

  private cancelTimeouts() {
    this.isQueueRunning = false
    clearTimeout(this.queueId as ReturnType<typeof setTimeout>)
    this.cleanQueue(false)
  }
}

export default YaloAnalytics
