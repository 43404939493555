import { IProduct } from '../../interfaces'

export enum EEventTypes {
  INTERACTION = 'INTERACTION',
  PAGE_VIEW = 'PAGE_VIEW',
  STEP = 'STEP',
}

export type eventNames =
  | 'addToCart'
  | 'removeFromCart'
  | 'clearCart'
  | 'deleteFromCart'
  | 'pageView'
  | 'checkoutStep'
  | 'sessionClosed'

export interface IGlobalSchema {
  eventType: keyof typeof EEventTypes
  storefront?: string
  eventName?: string
  phoneNumber: string
  sessionId: string
  // name of the window or modal in wich was triggered the event call
  viewName: string
  // used for track checkout steps where step 1 is catalogue view  and step 4 is confirm order modal
  payload?: ICartSchema | ITrackCheckoutStepSchema | IPageView
  errors?: string[]
}

export type IProductGenericBase = Pick<
  IProduct,
  'quantity' | 'name' | 'brand' | 'id' | 'price' | 'category' | 'sku'
>

interface ICartSchema {
  cart: {
    products?: IProductGenericBase[]
    product?: IProductGenericBase | null
    currency?: string
    total: number
    totalItems: number
  }
}

interface ITrackCheckoutStepSchema {
  step: number
  stepName?: string
}

interface IPageView {
  isModal: boolean
}

export interface IBaseEventPayload {
  eventName: eventNames
  phoneNumber: string
  sessionId: string
  viewName: string
  storefront?: string
}

export interface ITrackCartEventPayload extends IBaseEventPayload {
  cartData: ICartSchema
}

export interface ITrackCheckoutStepPayload extends IBaseEventPayload {
  stepData: ITrackCheckoutStepSchema
}

export interface ITrackPageViewPayload extends IBaseEventPayload {
  pageData: IPageView
}

export type SchemaBatch = IGlobalSchema[]
