import { useCallback, useRef } from 'react'
import { ApolloError } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import Hotjar from '@hotjar/browser'
import {
  SubItems,
  SubItem,
} from '@engyalo/delivery-ui-components/lib/interfaces/catalog'
import useCartAddProduct from '../../graphQL/mutations/cartAddProduct'
import useAppDispatch from '../useAppDispatch'
import { setSynchronizingCart } from '../../redux/slices/catalog'
import { debounceById } from '../../utils/debounce'
import { useGetCart } from './useGetCart'
import {
  IAddCartProductArgs,
  IGenericFunction,
  TBundle,
} from '../../interfaces'
import useAppSelector from '../useAppSelector'
import { generateDataDogContext } from '../../utils/dataDog'
import { onCartResponse } from '../../utils/onCartResponse'
import { IProductCart } from '../../graphQL/mutations/cartAddProduct/types'
import { addAlert } from '../../redux/slices/config'
import getAlertErrorMessage, {
  AlertMessageType,
} from '../../utils/getAlertErrorMessage'
import { sendGoogleEvent } from '../../utils/sendGoogleAnalytics'
import { Referrers } from '../../consts/defaultConfigValues/defaultConstants'
import { isSubItem } from '../../utils/isSubItem'
import validateApolloErrorCartAction from '../../utils/validateApolloErrorCartAction'
import YaloAnalytics from '../../modules/analytics'
import { ITrackCartEventPayload } from '../../modules/interfaces'
import { IProduct } from '../../interfaces'
import { useLocation } from 'react-router-dom'
import formatDataForAnalytics from '../../utils/formatDataForAnalytics'

type ValidationsCondition = (
  isFromCatalog: boolean,
  isBundle: boolean,
  hasEditSubItem: boolean,
  subItem: SubItem | TBundle | undefined,
) => boolean

type ValidationAction = (
  currentSubItems: SubItems,
  newSubItems: SubItem | TBundle,
  newValue: number,
  previousSubItem?: SubItem | null,
) => SubItems

type ValidateActions = [
  condition: ValidationsCondition,
  action: ValidationAction,
][]

const callbackDebounceTime = process.env.REACT_APP_CART_ACTION_DEBOUNCE_TIME
  ? +process.env.REACT_APP_CART_ACTION_DEBOUNCE_TIME
  : 500

const isCatalogSubItem = (
  isFromCatalog: boolean,
  isBundle: boolean,
  hasEditSubItem: boolean,
  subItem: SubItem | TBundle | undefined,
) => isFromCatalog && isBundle && !hasEditSubItem && !isSubItem(subItem)

const isCartSubItem = (
  isFromCatalog: boolean,
  isBundle: boolean,
  hasEditSubItem: boolean,
  subItem: SubItem | TBundle | undefined,
) => !isFromCatalog && isBundle && !hasEditSubItem && isSubItem(subItem)

const isEditSubItem = (
  isFromCatalog: boolean,
  isBundle: boolean,
  hasEditSubItem: boolean,
  subItem: SubItem | TBundle | undefined,
) => !isFromCatalog && isBundle && hasEditSubItem && !isSubItem(subItem)

const handleCatalogSubItem = (
  currentSubItems: SubItems,
  newSubItems: SubItem | TBundle,
  newValue: number,
) => {
  if (isSubItem(newSubItems)) {
    return []
  }
  const handledSubItems = currentSubItems || []
  handledSubItems.push({
    items: {
      ...newSubItems,
    },
    quantity: newValue,
  })
  return handledSubItems
}

const handleCartSubItem = (
  currentSubItems: SubItems,
  newSubItems: SubItem | TBundle,
  newValue: number,
) => {
  if (!isSubItem(newSubItems)) {
    return []
  }
  const handledSubItems = currentSubItems || []
  const hasPreviousSubItems = !!handledSubItems.length
  const indexOfBundle = hasPreviousSubItems
    ? handledSubItems?.findIndex((item) => {
        return item.hash === newSubItems.hash
      })
    : 0
  handledSubItems[indexOfBundle] = {
    ...newSubItems,
    items: {
      ...newSubItems.items,
    },
    quantity: newValue,
  }
  return handledSubItems
}

const handleEditSubItem = (
  currentSubItems: SubItems,
  newSubItems: SubItem | TBundle,
  newValue: number,
  previousSubItem?: SubItem | null,
) => {
  if (!previousSubItem) {
    return []
  }
  if (isSubItem(newSubItems)) {
    return []
  }
  const handledSubItems = currentSubItems || []
  const indexOfBundle = handledSubItems?.findIndex((item) => {
    return item.hash === previousSubItem.hash
  })
  handledSubItems[indexOfBundle] = {
    ...previousSubItem,
    items: {
      ...newSubItems,
    },
    quantity: newValue,
  }
  return handledSubItems
}

export const useAddItemToCart = () => {
  const dispatch = useAppDispatch()
  const abortControllers = useRef<Record<string, AbortController>>({})
  const state = useAppSelector((stateRedux) => stateRedux)
  const cart = useAppSelector((stateRedux) => stateRedux.cartSlice)
  const { subItemEdit } = state.catalogSlice
  const { config, sessionId, storeName, sessionData } = state.defaultSlice

  const getCartAction = useGetCart()

  const onCompleted = (data: IProductCart) => {
    if (data.cartAddProduct) {
      onCartResponse({
        data: data.cartAddProduct,
        state,
        dispatch,
      })
    }
  }

  const onError = (error: ApolloError) => {
    const alertType = validateApolloErrorCartAction(
      error,
      AlertMessageType.AddItem,
    )
    if (alertType) {
      const message = getAlertErrorMessage(alertType, config?.texts)
      dispatch(addAlert({ message }))
      getCartAction()
      const context = generateDataDogContext({
        title: 'Add product mutation error',
        extraInfo: { function: 'useAddItemToCart > onError' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(error, context)
    }
  }

  const [addProduct] = useCartAddProduct({
    onCompleted,
  })

  const location = useLocation()

  const execAddCartMutation = async (
    quantity: number,
    sku: string,
    product: IProduct,
    referrer?: Referrers,
    subItems?: SubItems,
    replace?: boolean,
    packageName?: string,
  ) => {
    try {
      dispatch(setSynchronizingCart(true))
      const controller = new window.AbortController()
      abortControllers.current[sku] = controller
      let actionLabel = 'Add to cart'
      if (referrer) {
        actionLabel = `Add to cart from ${referrer}`
      }
      const category = 'cart'
      Hotjar.event(actionLabel)
      sendGoogleEvent(category, actionLabel, sku, quantity)
      Hotjar.event(actionLabel)
      window.fbq('track', 'AddToCart', { sku, quantity })
      const currency = config?.options?.currency || ''
      const analyticsData: ITrackCartEventPayload = formatDataForAnalytics(
        location.pathname,
        quantity,
        cart,
        product,
        currency,
        sessionData?.customer?.phoneNumber || [''],
        sessionId,
        'addToCart',
        storeName,
      )
      YaloAnalytics.trackCartEvent(analyticsData)
      await addProduct({
        variables: {
          cartAddProductSessionUid: sessionId || '',
          cartAddProductStorefrontName: storeName || '',
          cartAddProductData: {
            sku,
            quantity,
            replace,
            referrer: referrer || undefined,
            subItems,
            package: packageName || undefined,
          },
        },
        context: { fetchOptions: { signal: controller.signal } },
        onError: (error) => {
          onError(error)
        },
      })
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not add product to cart',
        extraInfo: { sku, quantity, function: 'execAddCartMutation' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    } finally {
      dispatch(setSynchronizingCart(false))
    }
  }

  const callBackAddProductToCart = useCallback(
    debounceById(execAddCartMutation as IGenericFunction, callbackDebounceTime),
    [],
  )

  const validations: ValidateActions = [
    [isCatalogSubItem, handleCatalogSubItem],
    [isCartSubItem, handleCartSubItem],
    [isEditSubItem, handleEditSubItem],
  ]

  const addItemAction = (args: IAddCartProductArgs) => {
    const {
      product,
      value,
      newValue,
      referrer,
      isBundle,
      newSubItems,
      currentSubItems,
      currentRoute,
      resultFixed,
      isPackage,
    } = args
    try {
      if (value !== newValue || isBundle || resultFixed) {
        const {
          sku,
          minQtyAllowed = 0,
          name,
          package: productPackage,
        } = product
        abortControllers.current[sku]?.abort()
        const isFromCatalog = currentRoute === 'catalog' && !subItemEdit
        const hasEditSubItem = !!subItemEdit && isSubItem(subItemEdit)
        const replaceCart = isBundle ? !isFromCatalog : true
        let handledSubItems: SubItems = currentSubItems || []
        const packageNameFromCart =
          (productPackage && productPackage.name) || null
        const packageName = isPackage ? name : packageNameFromCart
        const validation = validations.find(([condition]) =>
          condition(
            isFromCatalog,
            isBundle || false,
            hasEditSubItem,
            newSubItems,
          ),
        )
        if (validation) {
          const [, action] = validation
          handledSubItems = action(
            handledSubItems,
            newSubItems || {},
            newValue,
            subItemEdit,
          )
        }
        const handledValue = isBundle
          ? handledSubItems.reduce((acc, item) => acc + item.quantity, 0)
          : newValue - minQtyAllowed
        const cleanedSubItems = handledSubItems?.map(
          ({ hash, ...subItemValue }) => subItemValue,
        )
        if (resultFixed) {
          const alertType = AlertMessageType.outOfStock
          const message = getAlertErrorMessage(
            alertType,
            config?.texts,
            product.stock || 0,
          )
          dispatch(addAlert({ message }))
        }
        callBackAddProductToCart(
          sku,
          handledValue,
          sku,
          product,
          referrer,
          cleanedSubItems,
          replaceCart,
          packageName,
        )
      }
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not add product to cart',
        extraInfo: { product, value, newValue, function: 'addItemAction' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    }
  }
  return { addItemAction, execAddCartMutation }
}

export default useAddItemToCart
