export enum DefaultContainerNames {
  PRODUCT_DETAIL = 'productDetail',
  PRODUCT_DETAIL_META = 'productDetailMeta',
  RECOMMENDED_PRODUCT_DETAIL = 'recommendedProductDetail',
}

export enum DefaultModalContainerNames {
  SEARCH_PRODUCT = 'searchProduct',
  PRODUCT_DETAIL = 'productDetail',
  RECOMMENDED_PRODUCTS = 'recommendedProducts',
  PREFILL_CART = 'prefillCart',
  RECOMMENDED_PRODUCT_DETAIL = 'recommendedProductDetail',
  FILTERS_CLEAR_CONFIRM = 'filtersClearConfirm',
  FILTERS_LIST = 'filtersList',
  LINKED_PRODUCTS = 'linkedProducts',
  CART_ADJUSTMENT = 'cartAdjustment',
  CHECKOUT_SESSION_RULE = 'linkedProductsCheckoutRule',
  CHOSEN_FREEGOODS = 'chosenFreegoods',
}

export enum DefaultDialogsContainerNames {
  STOCK_ALERT = 'stockAlert',
  CLEAR_CART = 'clearCart',
  VALIDATE_ALCOHOL = 'validateAlcohol',
  VALIDATE_RETURNABLE = 'validateReturnable',
  CONFIRM_ORDER = 'confirmOrder',
  REMOVE_ITEM = 'deleteItemFromCart',
}

// Ideally this would be the union of multiple split enums, but typescript doesn't support that
export enum CinnamonActionNames {
  CLOSE_MODAL = 'closeModal',
  OPEN_MODAL = 'openModal',
  OPEN_DIALOG = 'openDialog',
  CLOSE_DIALOG = 'closeDialog',
  PUSH_VIEW = 'pushView',
  CONDITIONAL_PUSH_VIEW = 'conditionalPushView',
  CLEAR_SEARCH = 'clearSearch',
  REMOVE_ITEM_FROM_CART = 'deleteItemFromCart',
  ADD_ITEM_TO_CART = 'addItemToCart',
  GO_BACK = 'goBack',
  GO_BACK_DYNAMIC = 'goBackDynamic',
  SEARCH = 'search',
  CREATE_ORDER = 'createOrder',
  LOAD_DIALOGS_VALIDATIONS = 'loadDialogsValidations',
  LOG_ACTION = 'logAction',
  GET_PRODUCT_DETAIL = 'productDetail',
  BUNDLES_HANDLER = 'bundlesHandler',
  SET_BUNDLE_COUNT = 'setCurrentBundleCount',
  SET_SUBITEMS = 'setSubitems',
  CLEAR_SUBITEMS = 'clearSubitems',
  CHOSEN_FREEGOODS = 'chosenFreegoods',
  CONFIRM_CHOSEN_FREEGOODS = 'confirmChosenFreegoods',
  NOTIFY_BACK_IN_STOCK = 'backInStockNotification',
  UPDATE_ITEMS_INPUT = 'adjustProductQuantity',
}

export enum TemplateActionNames {
  CART_DELETE_PRODUCT = 'templateActions.cart.deleteProduct',
  CART_CLEAR_CART = 'templateActions.cart.clearCart',
  CART_PLACE_ORDER = 'templateActions.cart.placeOrder',
  CART_GET_FREQUENTLY_BOUGHT_TOGETHER = 'templateActions.cart.getFrequentlyBoughtTogether',
  CART_CLOSE_RECOMMENDED_PRODUCTS_MODAL = 'templateActions.cart.closeRecommendedProducts',
  CART_UPDATE_RECOMMENDED_PRODUCT_BUTTON = 'templateActions.cart.updateRecommendedButton',
  CATALOG_SCROLL_TO_CATEGORY = 'templateActions.catalog.scrollToCategory',
  CATALOG_UPDATE_CATEGORY = 'templateActions.catalog.updateCategory',
  CATALOG_UPDATE_CATEGORY_CATALOG = 'templateActions.catalog.updateCategoryCatalog',
  CATALOG_BANNERS_CLICK = 'templateActions.catalog.bannersClick',
  FILTERS_SET_FILTERS = 'templateActions.filters.setFilters',
  FILTERS_SET_FILTER_CATEGORY = 'templateActions.filters.setFilterCategory',
  FILTERS_CLEAR_FILTERS = 'templateActions.filters.clearFilters',
  FILTERS_GET_FILTERS_RESULTS = 'templateActions.filters.getFilterResults',
  SUGGESTED_ITEMS_FETCH_PRODUCTS = 'templateActions.suggestedItems.fetchProducts',
  PREFILL_CART_UPDATE_ITEM = 'templateActions.prefillCart.updatePrefillCartItem',
  PREFILL_CART_TO_CART = 'templateActions.prefillCart.prefillCartToCart',
  PLACE_ORDER_FLOW = 'templateActions.placeOrderFlow',
  PLACE_ORDER_FLOW_NEXT = 'templateActions.placeOrderFlowNext',
  PLACE_ORDER_CLEAR = 'templateActions.clearPlaceOrderFlow',
  LINKED_PRODUCTS_GET_ITEMS = 'templateActions.getLinkedProducts',
  LINKED_PRODUCTS_CLOSE_MODAL = 'templateActions.placeOrderFlowClear',
  LINKED_PRODUCTS_VALIDATE_STOCK = 'templateActions.validateLinkedProducts',
  CLOSE_GLOBAL_PROMOTIONS_ALERT = 'templateActions.closeGlobalPromotionsAlert',
  CATEGORIES_NAVIGATION_SET_CATEGORIES = 'templateActions.categoriesNav.setCategoriesNavigation',
  CATEGORIES_NAVIGATION_NAVIGATE_TO_CATEGORY = 'templateActions.categoriesNav.navigateToCategory',
  CATEGORIES_NAVIGATION_GET_PRODUCTS = 'templateActions.categoriesNav.getProducts',
  CATEGORIES_LAZY_GET_PRODUCTS = 'templateActions.categories.lazyGetProducts',
  PRODUCT_DETAIL_ADD_FROM_PROMOTION = 'templateActions.productDetail.addFromPromotion',
}

export enum DefaultCheckoutRulesNames {
  MIN_AMOUNT_CART_CHECKOUT_RULE = 'comp-min-amount',
  MIN_QTY_CART_CHECKOUT_RULE = 'comp-min-qty',
  MAX_AMOUNT_CART_CHECKOUT_RULE = 'comp-max-amount',
  MAX_QTY_CART_CHECKOUT_RULE = 'comp-max-qty',
  CREDIT_LIMIT_REACHED = 'comp-credit-limit-reached',
}

export enum CinnamonPaths {
  HOME = '/{{params.slug}}/{{params.sessionId}}',
  CART = '/{{params.slug}}/{{params.sessionId}}/cart',
  SKU_MODAL = '?sku={{this.data.sku}}',
  SKU_RELATIVE = '/{{params.slug}}/{{params.sessionId}}/product/{{this.data.sku}}',
  SKU_RELATIVE_RECOMMENDED = '/{{params.slug}}/{{params.sessionId}}/recommended-product/{{this.data.sku}}',
  CATEGORIES = '/{{params.slug}}/{{params.sessionId}}/categories',
  FILTERS = '/{{params.slug}}/{{params.sessionId}}/filters',
  SUMMARY = '/{{params.slug}}/{{params.sessionId}}/summary',
  CATEGORIES_NAVIGATION = '/{{params.slug}}/{{params.sessionId}}/categories-nav',
  CATEGORY_NAVIGATION = '/{{params.slug}}/{{params.sessionId}}/category-nav',
}

export enum CinnamonVariables {
  SESSION_ID = '{{default.sessionId}}',
  SESSION_DATA = '{{default.sessionData}}',
  STOREFRONT_NAME = '{{default.storeName}}',
  WORKFLOW_ID = '{{default.sessionData.workflow.channelUid}} || {{config.phoneNumber}}',
  CART = '{{cart}}',
  SUMMARY = '{{summary}}',
  LINKED_PRODUCTS_BUTTON_LABEL = '{{cart.linkedProducts.continueButton}}',
}

export enum TextLibrary {
  ACTIONS_ACCEPT = '{{texts.actions.accept}}',
  ACTIONS_CANCEL = '{{texts.actions.cancel}}',
  ACTIONS_YES = '{{texts.actions.yes}}',
  ACTIONS_NO = '{{texts.actions.no}}',
  PRODUCT_STOCK = '{{texts.product.stock}}',
  PRODUCT_WITHOUT_STOCK = '{{texts.product.noStock}}',
  PRODUCT_STOCK_TITLE = '{{texts.product.stockTitle}}',
  PRODUCT_STOCK_MESSAGE = '{{texts.product.stockMessage}}',
  PRODUCT_UNIT_DISPLAY = '{{texts.product.unitPriceDisplay}}',
  PRODUCT_FEE_DISPLAY = '{{texts.product.feeDisplay}}',
  PRODUCT_TOTAL_AMOUNT = '{{texts.product.totalAmount}}',
  PRODUCT_OUT_OF_STOCK_BUTTON_LABEL = '{{texts.productOutOfStock.buttonLabel}}',
  PRODUCT_OUT_OF_STOCK_DIALOG_CONFIRM = '{{texts.productOutOfStock.dialogConfirm}}',
  PRODUCT_OUT_OF_STOCK_DIALOG_CANCEL = '{{texts.productOutOfStock.dialogCancel}}',
  PRODUCT_OUT_OF_STOCK_DIALOG_TITLE = '{{texts.productOutOfStock.dialogTitle}}',
  CART_TITLE = '{{texts.cart.title}}',
  CART_GO_TO_BUTTON = '{{texts.cart.goToButton}}',
  CART_PROCEED_TO_SUMMARY = '{{texts.cart.proceedToSummary}}',
  CART_PLACE_BUTTON = '{{texts.cart.placeButton}}',
  CART_CLEAR_BUTTON = '{{texts.cart.clearButton}}',
  CART_CLEAR_TITLE = '{{texts.cart.clearTitle}}',
  CART_CLEAR_MESSAGE = '{{texts.cart.clearMessage}}',
  CART_REMOVE_TITLE = '{{texts.cart.removeTitle}}',
  CART_REMOVE_MESSAGE = '{{texts.cart.removeMessage}}',
  CART_EMPTY_TITLE = '{{texts.cart.emptyTitle}}',
  CART_EMPTY_MESSAGE = '{{texts.cart.emptyMessage}}',
  CART_CONFIRM_TITLE = '{{texts.cart.confirmTitle}}',
  CART_CONFIRM_MESSAGE = '{{texts.cart.confirmMessage}}',
  CART_BACK_HOME = '{{texts.cart.backHome}}',
  CART_MINIMUN_MESSAGE = '{{texts.cart.minimunMessage}}',
  CART_CASH_GROUP = '{{texts.cart.cashGroup}}',
  CART_CREDIT_GROUP = '{{texts.cart.creditGroup}}',
  CART_PRODUCTS_GROUP = '{{texts.cart.productsGroup}}',
  CART_PROMOTIONS_GROUP = '{{texts.cart.promotionsGroup}}',
  CART_BOX_GROUP = '{{texts.cart.boxGroup}}',
  CART_UNIT_GROUP = '{{texts.cart.unitGroup}}',
  CART_ADD_TO_CART = '{{texts.cart.addToCart}}',
  SEARCH_PLACEHOLDER = '{{texts.search.placeholder}}',
  SEARCH_EMPTY_TITLE = '{{texts.search.emptyTitle}}',
  LOADING_MORE_ELEMENTS_TEXT = '{{texts.catalogue.loadingMoreElementsText}}',
  SEARCH_EMPTY_MESSAGE = '{{texts.search.emptyMessage}}',
  SEARCH_NO_RESULT_TITLE = '{{texts.search.noResultTitle}}',
  SEARCH_NO_RESULT_MESSAGE = '{{texts.search.noResultMessage}}',
  SEARCH_RESULTS_TITLE = '{{texts.search.resultsTitle}}',
  TAGS_ALCOHOL = '{{texts.tags.alcohol}}',
  TAGS_RETURNABLE = '{{texts.tags.returnable}}',
  TAGS_SHORT_ALCOHOL = '{{texts.tags.shortAlcohol}}',
  TAGS_SHORT_RETURNABLE = '{{texts.tags.shortReturnable}}',
  TAGS_LINKED_PRODUCTS = '{{texts.tags.linkedProducts}}',
  PREFILL_CART_TITLE = '{{texts.prefillCart.title}}',
  PREFILL_CART_SUBTITLE = '{{texts.prefillCart.subtitle}}',
  PREFILL_CART_SUBTITLE_SINGULAR = '{{texts.prefillCart.subtitleSingular}}',
  RECOMMENDED_PRODUCTS_TITLE = '{{texts.recommendedProducts.title}}',
  RECOMMENDED_PRODUCTS_DECLINE = '{{texts.recommendedProducts.decline}}',
  RECOMMENDED_PRODUCTS_PROCEED = '{{texts.recommendedProducts.proceed}}',
  RECOMMENDED_PRODUCTS_HEADER_TITLE = '{{texts.recommendedProducts.headerTitle}}',
  RECOMMENDED_PRODUCTS_HEADER_BUTTON = '{{texts.recommendedProducts.headerButton}}',
  CREDIT_LIMIT_REACHED = '{{texts.catalogue.creditLimitReached}}',
  EMPTY_CATEGORY_TITLE = '{{texts.catalogue.emptyCategoryTitle}}',
  EMPTY_CATEGORY_MESSAGE = '{{texts.catalogue.emptyCategory}}',
  EMPTY_CATEGORY_BACK_BUTTON = '{{texts.catalogue.backToCategoriesOnEmpty}}',
  SEE_ALL_CATEGORIES_BUTTON = '{{texts.catalogue.seeAllCategories}}',
  ALL_CATEGORIES_LABEL = '{{texts.catalogue.allCategories}}',
  CATEGORIES_LABEL = '{{texts.catalogue.categories}}',
  FILTERS_VIEW_TITLE = '{{texts.filters.title}}',
  FILTERS_APPLY_BUTTON_TEXT = '{{texts.filters.buttonText}}',
  FILTERS_CLEAR_BUTTON_TEXT = '{{texts.filters.clearText}}',
  FILTERS_MODAL_CLEAR_TITLE = '{{texts.filters.clearModalTitle}}',
  FILTERS_MODAL_BUTTON_OK = '{{texts.filters.clearModalButtonOK}}',
  FILTERS_MODAL_BUTTON_CANCEL = '{{texts.filters.clearModalButtonCancel}}',
  FILTERS_ALL_PRODUCTS_LABEL = '{{texts.filters.allProductsLabel}}',
  FILTERS_EMPTY_RESULT = '{{texts.filters.emptyResult}}',
  FILTERS_EMPTY_RESULT_DESCRIPTION = '{{texts.filters.emptyResultDescription}}',
  SUGGESTED_ITEMS_TITLE = '{{texts.suggestedItems.title}}',
  SUGGESTED_ITEMS_EMPTY_RESULT = '{{texts.suggestedItems.emptyResult}}',
  SUGGESTED_ITEMS_EMPTY_RESULT_DESCRIPTION = '{{texts.suggestedItems.emptyResultDescription}}',
  SUGGESTED_ITEMS_SUBTITLE = '{{texts.suggestedItems.subtitle}}',
  LINKED_PRODUCTS_HEADING = '{{texts.linkedProducts.heading}}',
  LINKED_PRODUCTS_CONTINUE = '{{texts.linkedProducts.continue}}',
  LINKED_PRODUCTS_CONTINUE_WITHOUT = '{{texts.linkedProducts.continueWithout}}',
  SUMMARY_TITLE = '{{texts.summary.title}}',
  SUMMARY_SUBTITLE = '{{texts.summary.subtitle}}',
  SUMMARY_UNITS_LABEL = '{{texts.summary.unitsLabel}}',
  SUMMARY_TOTAL_LABEL = '{{texts.summary.totalLabel}}',
  SUMMARY_SIDE_LABEL = '{{texts.summary.sideLabel}}',
  SUMMARY_SUBTOTAL_LABEL = '{{texts.summary.subtotalLabel}}',
  SUMMARY_CONTINUE_BUTTON = '{{texts.summary.continue}}',
  SUMMARY_HEADING_QUANTITY = '{{texts.summary.headingQuantity}}',
  SUMMARY_HEADING_PRODUCT = '{{texts.summary.headingProduct}}',
  SUMMARY_HEADING_SUBTOTAL = '{{texts.summary.headingSubtotal}}',
  SUMMARY_DISCOUNTS_LABEL = '{{texts.summary.discountsLabel}}',
  SUMMARY_TAXES_LABEL = '{{texts.summary.taxesLabel}}',
  SUMMARY_PERCEPTIONS_LABEL = '{{texts.summary.perceptionsLabel}}',
  SUMMARY_ADMINISTRATIVE_SERVICE_LABEL = '{{texts.summary.administrativeServiceLabel}}',
  SUMMARY_ALERT_ON_MIN_AMOUNT = '{{texts.summary.alertOnMinAmount}}',
  CART_SERVICE_LABEL = '{{texts.cart.serviceLabel}}',
  CART_MATERIAL_LABEL = '{{texts.cart.materialLabel}}',
  CART_PERCEPTION_LABEL = '{{texts.cart.perceptionLabel}}',
  CART_MAX_QTY_ALERT = '{{texts.cart.warningLabel}}',
  CART_RESTRICTIONS_LABEL = '{{texts.cart.restrictionsLabel}}',
  CART_TOTAL_LABEL = '{{texts.cart.totalCartFooter}}',
  CART_ITEMS_COUNTER = '{{texts.cart.itemsCounter}}',
  CART_PRICE_TEXT = '{{texts.cart.price}}',
  CHOSEN_FREEGOODS_HEADER = '{{texts.chosenFreegoods.heading}}',
  CHOSEN_FREEGOODS_DESCRIPTION = '{{texts.chosenFreegoods.description}}',
  CHOSEN_FREEGOODS_CONFIRM = '{{texts.chosenFreegoods.confirmLabel}}',
  CHOSEN_FREEGOODS_REJECT = '{{texts.chosenFreegoods.rejectLabel}}',
  BUNDLE_LIST_TITLE = '{{texts.bundles.title}}',
  BUNDLE_LIST_DESCRIPTION_COUNTERS = '{{texts.bundles.descriptionForCounters}}',
  BUNDLE_LIST_DESCRIPTION_CHECKBOX = '{{texts.bundles.descriptionForCheckBox}}',
  BUNDLE_LIST_DESCRIPTION_RADIO = '{{texts.bundles.descriptionForRadio}}',
  BUNDLE_LIST_SELECT_BUTTON_LABEL = '{{texts.bundles.selectButtonLabel}}',
  PACKAGE_LIST_DESCRIPTION = '{{texts.packages.description}}',
  PACKAGE_LIST_TITLE = '{{texts.packages.title}}',
  PACKAGE_LIST_OUT_OFF_STOCK = '{{texts.packages.outOfStock}}',
  DEFAULT_PROMOTION_LABEL = '{{texts.promotions.defaultPromotionLabel}}',
  PROMOTION_ADD_LABEL = '{{texts.promotions.addLabel}}',
}

export enum Referrers {
  PREFILL_CART = 'PREFILL_CART',
  FREQUENTLY_BOUGHT_TOGETHER = 'FREQUENTLY_BOUGHT_TOGETHER',
  SUGGESTED_ITEMS = 'SUGGESTED_ITEMS',
  LINKED_PRODUCTS = 'LINKED_PRODUCTS',
  PROMOTION_DETAIL = 'PROMOTION_DETAIL',
}

export const FIRST_PAGE_NUMBER = 1
export const PRODUCTS_PAGE_SIZE = 10
export const CATEGORIES_PAGE_SIZE = 25
export const CATEGORIES_PAGE_SIZE_NAVIGATION = 50
export const PREFILL_CART_LIMIT = 100
export const WHATSAPP_ME_URL = 'http://wa.me/'
export const CHATBOT_WARNING_ICON =
  'https://storage.googleapis.com/commerce-cinnamon-production/images/assets/assistant_warning.svg'

export enum PackageType {
  HALFBOX = 'HALF BOX',
  UNIT = 'UNIT',
}

export const EMPTY_STRING = ''
export const SlashSymbol = '/'
export const TrueEnv = 'true'
