import { SchemaBatch } from '../interfaces'

const url = process.env.REACT_APP_ANALYTICS_URL || ''
const token = process.env.REACT_APP_ANALYTICS_URL_TOKEN || ''

export const pushData = async (data: SchemaBatch) => {
  await fetch(url, {
    headers: { 'x-api-key': token, 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export const sendBeacon = (data: SchemaBatch) =>
  navigator.sendBeacon(url, JSON.stringify(data))
