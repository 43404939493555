export interface ICalculateNewQuantity {
  originalValue: number
  minQtyAllowed: number
  promotionalQty: number
  inRangeValue: number
  autoAdd: boolean
  freegoodsAsSubitem: boolean
  jumpQty: number | string
}

export const recalculateValueFromInput = ({
  originalValue,
  minQtyAllowed,
  inRangeValue,
  jumpQty,
  autoAdd,
  freegoodsAsSubitem,
  promotionalQty,
}: ICalculateNewQuantity): number => {
  if (typeof jumpQty === 'number' && jumpQty > 1) {
    const updatedOriginalValue =
      !autoAdd && freegoodsAsSubitem
        ? originalValue + promotionalQty
        : originalValue

    const quantityWithoutMin = updatedOriginalValue - minQtyAllowed
    const module = quantityWithoutMin % jumpQty
    const adjustedQuantity =
      module > 0 ? updatedOriginalValue - module : updatedOriginalValue
    let finalAdjustedQuantity =
      adjustedQuantity < minQtyAllowed ? minQtyAllowed : adjustedQuantity

    return finalAdjustedQuantity
  }
  return inRangeValue
}

export default recalculateValueFromInput
