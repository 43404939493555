import {
  ITrackCartEventPayload,
  IProductGenericBase,
  eventNames,
} from '../modules/interfaces'
import { IProduct, ICart } from '../interfaces'

const formatDataForAnalytics = (
  location: string,
  quantity: number,
  cart: ICart,
  product: IProduct | null,
  currency: string | null,
  phoneNumber: Array<string> = [''],
  sessionId: string = '',
  eventName: eventNames,
  storefront: string = '',
): ITrackCartEventPayload => {
  const productsTransformed: IProductGenericBase[] = cart.items.map(
    (item) =>
      ({
        quantity: item.quantity,
        name: item.name,
        brand: item.brand || '',
        id: item.id,
        price: item.price,
        category: '',
        sku: item.sku,
      }) as IProductGenericBase,
  )
  const productData = {
    quantity,
    sku: product?.sku || '',
    brand: product?.brand || '',
    category: product?.category || '',
    id: product?.id || '',
    name: product?.name || '',
    price: product?.price || 0,
  }
  let cartData = {
    cart: {
      product: productData,
      products: productsTransformed,
      currency: currency || '',
      total: cart.total || 0,
      totalItems: cart.items.length || 0,
    },
  }

  return {
    cartData,
    eventName,
    phoneNumber: phoneNumber[0],
    sessionId,
    viewName: location,
    storefront,
  }
}

export default formatDataForAnalytics
